



















import {
	Component,
	Vue
} from "vue-property-decorator";
// import vVideoModule from '@/components/v-video-module.vue'
import vFreeApply from '@/components/v-free-apply.vue';
import defaultItem from "@/utils/defaultItem";

@Component({
	components: {
		vFreeApply
		// vVideoModule,
	}
})
export default class extends Vue {
	private current = 0
	private tabs = [{
			active: 0,
			name: "黑谷资讯"
		},
		{
			active: 1,
			name: "黑谷动态"
		},
	]
	private list = defaultItem.infomationList()
	// 切换资讯与动态
	onTabs(val: any) {
		this.current = val
		if (val == 0) {
			this.list = defaultItem.infomationList()
		}
		if (val == 1) {
			this.list = defaultItem.dynamicList()
		}
	}
	// 点击跳转详情
	navigetor(item: any) {
		const url = this.$router.resolve({
			path: `/dynamicDetails/${item.id}`
		})
		this.$router.push({
			path: url.href + location.search,
			query: {}
		})
		// window.open(url.href+location.search, '_blank')
	}

}
